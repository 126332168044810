import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

// Importing custom components that represent individual nav items
import SideBarData from "../SideBarItem/SideBarData";
import ToggleNavData from "../SideBarItem/ToggleNavData";

// Data imports - assuming these are arrays of objects like { label: 'Home', path: '/' }
import { sidebarItems, bottomSidebarItems } from "../../data/SideBarData";
import { toggleItems, toggleBottomItems } from "../../data/ToggleData";

function SideNavDashBoard() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 750);

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 750);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false); // This will close the sidebar
  };

  return (
    <div className={isOpen ? "bg-blue-500" : ""}>
      {isLargeScreen ? (
        <div className="w-72 h-screen bg-blue-500 overflow-hidden">
          <div className="p-3 text-center bg-gray-100 h-full">
            <div className="text-xl">
              <div className="text-4xl font-bold text-secondary">
                Nexa<span className="text-primary font-semibold">Cura</span>
              </div>
            </div>
            <div className="flex flex-col justify-around h-full py-10">
              {sidebarItems.map((item) => (
                <SideBarData
                  key={item.label}
                  {...item}
                  closeNav={closeSidebar}
                />
              ))}
              {bottomSidebarItems.map((item) => (
                <SideBarData
                  key={item.label}
                  {...item}
                  closeNav={closeSidebar}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <button
            className={`absolute top-3 left-3 z-50  ${
              isOpen ? "text-blue-500" : "text-white"
            }`}
            onClick={toggleSidebar}
            // style={{ color: isOpen ? "white" : "primary" }}
          >
            {isOpen ? (
              <AiOutlineMenuFold className="text-4xl text-white" />
            ) : (
              <AiOutlineMenuUnfold className="text-4xl  text-blue-500" />
            )}
          </button>
          {isOpen && (
            <div className="w-screen h-screen bg-blue-500 flex flex-col text-center">
              <div className="p-3 text-center overflow-y-auto flex-col">
                <div className="text-xl">
                  <div className="text-4xl font-bold text-secondary">
                    Nexa<span className="text-white font-semibold">Cura</span>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between h-full py-10 ">
                  {toggleItems.map((item) => (
                    <ToggleNavData
                      key={item.label}
                      {...item}
                      closeNav={closeSidebar}
                    />
                  ))}
                  {toggleBottomItems.map((item) => (
                    <ToggleNavData
                      key={item.label}
                      {...item}
                      closeNav={closeSidebar}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SideNavDashBoard;
