import React from "react";

function Terms() {
  return (
    <div class="bg-gray-100">
      <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Terms and Conditions</h1>
        <p class="mb-4">
          Welcome to our AI Psychologist application. These terms and conditions
          outline the rules and regulations for the use of our application. By
          accessing this app, you accept these terms and conditions in full. Do
          not continue to use this application if you do not agree to all of the
          terms and conditions stated on this page.
        </p>

        <h2 class="text-2xl font-bold mb-2">1. Intellectual Property Rights</h2>
        <p class="mb-4">
          Other than the content you own, under these terms, we and/or our
          licensors own all the intellectual property rights and materials
          contained in this application. You are granted a limited license only
          for purposes of viewing the material contained on this application.
        </p>

        <h2 class="text-2xl font-bold mb-2">2. Disclaimer</h2>
        <p class="mb-4">
          The materials on this application are provided on an 'as is' basis. We
          make no warranties, expressed or implied, and hereby disclaim and
          negate all other warranties including, without limitation, implied
          warranties or conditions of merchantability, fitness for a particular
          purpose, or non-infringement of intellectual property or other
          violation of rights.
        </p>

        <h2 class="text-2xl font-bold mb-2">3. Limitation of Liability</h2>
        <p class="mb-4">
          In no event shall we, nor any of our officers, directors and
          employees, be held liable for anything arising out of or in any way
          connected with your use of this application whether such liability is
          under contract, tort or otherwise, and we, including our officers,
          directors and employees shall not be liable for any indirect,
          consequential or special liability arising out of or in any way
          related to your use of this app.
        </p>

        <h2 class="text-2xl font-bold mb-2">4. Indemnification</h2>
        <p class="mb-4">
          You hereby indemnify to the fullest extent us from and against any
          and/or all liabilities, costs, demands, causes of action, damages and
          expenses arising in any way related to your breach of any of the
          provisions of these terms.
        </p>

        <h2 class="text-2xl font-bold mb-2">5. Severability</h2>
        <p class="mb-4">
          If any provision of these terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </p>

        <h2 class="text-2xl font-bold mb-2">6. Variation of Terms</h2>
        <p class="mb-4">
          We are permitted to revise these terms at any time as it sees fit, and
          by using this application you are expected to review these terms on a
          regular basis.
        </p>

        <h2 class="text-2xl font-bold mb-2">7. Assignment</h2>
        <p class="mb-4">
          We are allowed to assign, transfer, and subcontract its rights and/or
          obligations under these terms without any notification. However, you
          are not allowed to assign, transfer, or subcontract any of your rights
          and/or obligations under these terms.
        </p>

        <h2 class="text-2xl font-bold mb-2">8. Entire Agreement</h2>
        <p class="mb-4">
          These terms constitute the entire agreement between us regarding your
          use of this application, and supersede all prior agreements and
          understandings.
        </p>

        <h2 class="text-2xl font-bold mb-2">9. Governing Law & Jurisdiction</h2>
        <p class="mb-4">
          These terms will be governed by and interpreted in accordance with the
          laws of the jurisdiction in which our company is located, and you
          submit to the non-exclusive jurisdiction of the state and federal
          courts located in our jurisdiction for the resolution of any disputes.
        </p>

        <h2 class="text-2xl font-bold mb-2">10. Refund Policy</h2>
        <p class="mb-4">
          If our server is down and inaccessible for more than 24 consecutive
          hours, customers are entitled to a full refund if claimed within 72
          hours of the incident. We also offer a one-hour session of our service
          to ensure satisfaction before committing to a bigger package purchase.
          If you are not satisfied with the one hour service, we recommend not
          proceeding with the purchase of a larger service package. All refund
          requests must be submitted in writing to our customer service team
          through the contact information provided on the application.
        </p>

        <h2 class="text-2xl font-bold mb-2">11. Your Responsibilities</h2>
        <p class="mb-4">
          You are responsible for ensuring that your use of this application is
          in compliance with laws and regulations applicable to you. You
          acknowledge that this application is not a replacement for
          professional mental health services and that you should seek
          professional advice if necessary.
        </p>

        <h2 class="text-2xl font-bold mb-2">12. AI Psychologist Limitations</h2>
        <p class="mb-4">
          While the AI Psychologist is designed to assist users with
          psychological support and advice, it is not intended to replace
          traditional psychologist-patient relationships. The AI cannot diagnose
          mental health conditions or offer professional counseling that would
          normally require a licensed human professional. Users should consider
          this application as a supportive tool rather than a complete solution
          for mental health needs.
        </p>

        <h2 class="text-2xl font-bold mb-2">13. Contact Information</h2>
        <p class="mb-4">
          For any inquiries or questions regarding these terms, please contact
          us through our provided contact methods on the application.
        </p>
      </div>
    </div>
  );
}

export default Terms;
