import React from "react";

function Privacy() {
  return (
    <div class="bg-gray-100 px-10 lg:pl-32">
      <div class="container mx-auto py-8">
        <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p class="mb-4">Last updated: 23rd of April 2024</p>
        <p class="mb-4">
          At Nexacura, accessible from nexacura.chat, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by Nexacura and
          how we use it.
        </p>

        <h2 class="text-2xl font-bold mb-2">Information Collection And Use</h2>
        <p class="mb-4">
          We collect several different types of information for various purposes
          to provide and improve our service to you.
        </p>
        <h3 class="text-xl font-bold mb-2">Types of Data Collected</h3>
        <h4 class="text-lg font-bold mb-2">Personal Data</h4>
        <p class="mb-4">
          While using our application, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <h4 class="text-lg font-bold mb-2">Usage Data</h4>
        <p class="mb-4">
          We may also collect information that your browser sends whenever you
          visit our service or when you access the service by or through a
          mobile device ("Usage Data").
        </p>
        <h4 class="text-lg font-bold mb-2">Tracking & Cookies Data</h4>
        <p class="mb-4">
          We use cookies and similar tracking technologies to track the activity
          on our service and hold certain information. Cookies are files with a
          small amount of data which may include an anonymous unique identifier.
          Cookies are sent to your browser from a website and stored on your
          device. Tracking technologies also used are beacons, tags, and scripts
          to collect and track information and to improve and analyze our
          service.
        </p>
        <p class="mb-4">
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our service.
        </p>

        <h2 class="text-2xl font-bold mb-2">Use of Data</h2>
        <p class="mb-4">
          Nexacura uses the collected data for various purposes:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>To provide and maintain the service</li>
          <li>To notify you about changes to our service</li>
          <li>
            To allow you to participate in interactive features of our service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the service
          </li>
          <li>To monitor the usage of the service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2 class="text-2xl font-bold mb-2">Transfer Of Data</h2>
        <p class="mb-4">
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection
          laws may differ from those from your jurisdiction.
        </p>
        <p class="mb-4">
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p class="mb-4">
          Nexacura will take all steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy
          and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>

        <h2 class="text-2xl font-bold mb-2">Disclosure Of Data</h2>
        <p class="mb-4">Legal Requirements</p>
        <p class="mb-4">
          Nexacura may disclose your Personal Data in the good faith belief that
          such action is necessary to:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of Nexacura</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            service
          </li>
          <li>
            Protect the personal safety of users of the service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <h2 class="text-2xl font-bold mb-2">Security Of Data</h2>
        <p class="mb-4">
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>

        <h2 class="text-2xl font-bold mb-2">Service Providers</h2>
        <p class="mb-4">
          We may employ third party companies and individuals to facilitate our
          service ("Service Providers"), to provide the service on our behalf,
          to perform service-related services or to assist us in analyzing how
          our service is used. These third parties have access to your Personal
          Data only to perform these tasks on our behalf and are obligated not
          to disclose or use it for any other purpose.
        </p>

        <h2 class="text-2xl font-bold mb-2">Links To Other Sites</h2>
        <p class="mb-4">
          Our service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p class="mb-4">
          We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third party sites or services.
        </p>

        <h2 class="text-2xl font-bold mb-2">Children's Privacy</h2>
        <p class="mb-4">
          Our service does not address anyone under the age of 18 ("Children").
        </p>
        <p class="mb-4">
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
