import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import subscriptions from "../../data/subscriptions";
import SubscriptionCard from "../../Components/Card/SubscriptionCard";
import Text from "../../Components/Text/Text";

const Chat = () => {
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const messageListRef = useRef();
  const [hasSubscription, setHasSubscription] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const res = await axios.get("/api/checkSubscription", {
          withCredentials: true,
        });
        setHasSubscription(res.data.hasSubscription);
      } catch (error) {
        console.error("Failed to check subscription status:", error);
      }
    };

    checkSubscription(); // Check subscription on component mount
  }, []);

  useEffect(() => {
    const fetchPastConversations = async () => {
      try {
        const response = await axios.get("/api/pastConversation", {
          headers: { "Content-Type": "application/json" },
          withCredentials: true, // Ensures cookies, such as session cookies, are sent with the request
        });
        if (response.data && response.data.conversation) {
          setMessages(response.data.conversation);
        } else {
          setMessages([
            {
              message:
                "Hi, it's Sophia your AI psychologist. How can I help you today?",
              sentTime: "Just now",
              sender: "agent",
            },
          ]);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 404 || error.response.status === 500)
        ) {
          setMessages([
            {
              message:
                "Hi, this is Sophia your AI psychologist. How can I help you today?",
              sentTime: "Just now",
              sender: "agent",
            },
          ]);
        }
      }
    };

    fetchPastConversations();
  }, []);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollToBottom("smooth");
    }
  }, [messages]);

  const handleSend = async (messageText) => {
    const userMessage = {
      message: messageText,
      sentTime: "Just now",
      sender: "user",
      direction: "outgoing",
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setTyping(true);

    try {
      const response = await axios.post("/api/chatbot", {
        chatMessages: [...messages, userMessage].map((msg) => ({
          sender: msg.sender === "user" ? "user" : "assistant",
          content: msg.message,
        })),
      });

      let chatGPTMessage = {};
      if (response.data && response.data.choices) {
        chatGPTMessage = {
          message: response.data.choices[0].message.content,
          sentTime: "Just now",
          sender: "agent",
          direction: "incoming",
        };
      }

      const delay = Math.random() * (4000 - 2000) + 2000; // Random delay between 2 and 4 seconds

      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, chatGPTMessage]);
        setTyping(false);
        sendConversationToBackend([...messages, userMessage, chatGPTMessage]);
        messageListRef.current.scrollToBottom("smooth");
      }, delay);
    } catch (error) {
      console.error("Error processing message:", error);
      setTyping(false);
    }

    messageListRef.current.scrollToBottom("smooth");
  };

  async function sendConversationToBackend(conversation) {
    try {
      await axios.post(
        "/api/chat",
        { conversation: conversation },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      console.error("Error sending conversation to backend:", error);
    }
  }

  return (
    <div className="flex h-screen">
      <div className="flex flex-col flex-grow h-full">
        <div className="flex flex-col flex-grow h-full pt-3 pb-2 ml-5 px-3">
          {hasSubscription ? (
            <div className="flex flex-col flex-grow h-full gap-8">
              <MainContainer className="flex flex-col flex-grow h-full max-h-[calc(100vh-200px)]">
                {/* Adjusted max-height */}
                <ChatContainer className="flex flex-col flex-grow h-full overflow-hidden">
                  <MessageList
                    ref={messageListRef}
                    typingIndicator={
                      typing ? (
                        <TypingIndicator content="Sophia is typing..." />
                      ) : null
                    }
                    scrollBehavior="smooth"
                    className="flex-grow overflow-auto"
                  >
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`mb-8 w-3/4 border-1 border-yellow-400 ${
                          message.sender === "user" ? "ml-auto" : ""
                        }`}
                      >
                        <Message
                          model={{
                            message: message.message,
                            sentTime: message.sentTime,
                            sender: message.sender,
                            direction:
                              message.sender === "user"
                                ? "outgoing"
                                : "incoming",
                          }}
                        />
                      </div>
                    ))}
                  </MessageList>
                </ChatContainer>
              </MainContainer>
              <div className="flex-none p-2 ">
                {" "}
                {/* Added extra padding to the bottom */}
                <MessageInput
                  attachButton={false}
                  placeholder="Type message here"
                  onSend={handleSend}
                />
              </div>
            </div>
          ) : (
            <div className="flex-grow h-full pt-9 pb-20">
              <Text className="text-2xl text-primary font-semibold">
                My Subscription
              </Text>
              <div className="grid xl:grid-cols-3 lg:grid-cols-2 gap-8 mt-6">
                {subscriptions.map((subscription) => (
                  <SubscriptionCard key={subscription.id} {...subscription} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
