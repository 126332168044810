import React from "react";
import PricingComponent from "../../Components/PricingComponent/PricingComponent";

function SelectPlan() {
  return (
    <div>
      <PricingComponent />
    </div>
  );
}

export default SelectPlan;
